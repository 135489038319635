import { 
    ChakraProvider, 
    Container, 
    Image,
    Box,
    Text,
    Radio, 
    RadioGroup,
    Stack,
    StackDivider,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
} from '@chakra-ui/react'
import React from 'react'
import './App.css'

import { withCookies } from "react-cookie"

const Questions = [
    {
        title: 'Cu ce mijloc de transport te deplasezi de obicei?',
        A: 'Bicicletă / Pe jos',
        B: 'Mașină electrică / hibrid',
        C: 'Autobuz / Metrou / Tramvai',
        D: 'Mașină pe benzină / Diesel',
        points: 1,
    },
    {
        title: 'Cât de des reciclezi?',
        A: 'Reciclez absolut tot',
        B: 'Destul de des',
        C: 'Nu prea cred în reciclare, dar mai fac asta uneori',
        D: 'Niciodată. Ar trebui?',
        points: 1,
    },
    {
        title: 'Stingi lumina când ieși dintr-o camera?',
        A: 'De fiecare dată',
        B: 'E pe senzori, se stinge singură',
        C: 'O uit aprinsă destul de des',
        D: 'Ce sens are? Oricum mă întorc acolo la un moment dat',
        points: 1,
    },
    {
        title: 'Cât de plant-based este meniul tău?',
        A: 'Foarte. Sunt vegetarian',
        B: 'Am o dietă prestabilită, mânânc echilibrat',
        C: 'Mănânc mai light doar vara',
        D: 'Coastele la grătar se pun?',
        points: 1,
    },
    {
        title: 'În ce format vin facturile tale?',
        A: '100% electronic. Salvez până la 2 copaci anual per factură',
        B: 'Am factura electronică, dar am păstrat-o și pe cea tiparită',
        C: 'Tipărite, dar mă gândesc să trec pe format electronic',
        D: 'Tipărite',
        points: 1,
    },
    {
        title: 'Vrei să iei parte la inițiativa #ActionForClimate?',
        A: 'Da, ca să avem un viitor nelimitat de verde',
        B: 'Da, dar trebuie să găsesc echilibru între confort și eficiență',
        C: 'Pare că fac alții deja asta, posibil să încep și eu',
        D: 'Nu cred, pare complicat',
        points: 3
    },
]

const Responses = {
    A: `Change Maker
Alfabetul tău începe cu litera V. Verde, viață mai bună, viitor curat.

Tot ce faci, faci ca să ai grijă de planetă, chiar și atunci când nimeni nu te vede. Mergi cu bicicleta peste tot, reciclezi până și coșurile de reciclare și nu-ți place deloc să arunci mâncare. Ai grijă de unde provine energia pe care o folosești și nu irosești nici măcar un Watt. Și nici hârtie nu-ți place să irosești, de asta ai fost early adopter cu factura electronică.
Felicitări, dacă am fi toți ca tine, viitorul nelimitat de verde pe care ni-l dorim ar fi prezentul. #ActionForClimate`,
    B: `TechMaster 3000:

Suntem siguri că ai optimiza și acest text descriptiv ca să consume mai puțină energie și să fie mai eficient. Pentru că așa ești tu, nu-ți place să irosești. Ai un app pentru fiecare electrocasnic din casă, îl pornești de la distanță și să fii eficient și cu timpul tău. De fapt, ai calculat deja consumul pe următorii 15 ani ca să nu te ia nimic prin surprindere. Ești un exemplu de urmat. Poate și de studiat, ar zice unii. În orice caz, #ActionForClimate e pentru oamenii ca tine, care își doresc un viitor verde și chiar fac ceva pentru asta.`,
    C: `Sceptizaurul

Ai tot auzit de oameni care fac lucrurile mai eficient, plătesc facturi mai mici, dar nu pare să fie ceva ce s-ar putea întâmpla prea curând pentru tine. Și ce dacă uiți lumina aprinsă uneori? Și ce dacă clasa energetică a frigiderului e mare? Tu ai văzut cum vine în bucătărie? Dar îi admiri pe cei care reușesc și parcă vrei și tu să eficientizezi consumul de energie. Poate nu acum. Într-un viitor apropiat? Începi cu puțin #ActionForClimate azi și vezi tu cum te mai simți mâine. Împreună putem face planeta un loc mai bun.`,
    D: `Conforționistul

Chiar avem nevoie de toate astea? Serios acum, abia ai terminat renovarea, cam târziu să pui panouri solare. Nu zice nimeni că nu vrei să faci mai multe pentru planetă, dar e așa de bine cu aerul condiționat mereu pornit! Dar parcă ar merge să mai economisești la factură, până la urmă, banii ăia îi investești tot în confortul tău. Poate nu panouri solare, dar un aer condiționat cu consum mai mic...? Poate că #ActionForClimate nu e o bătaie de cap atââât de mare. Fiecare mică acțiune contează. Împreună putem crea un viitor mai sustenabil.`,
}

const Password = 'Action4Climate'

class Eon extends React.Component {
    state = {
        currentQuestionIndex: 0,
        answers: [],
        isShowResult: false,
        password: '',
        isErrorAuth: false,
        isAuth: false
    }

    componentDidMount() {
        const { cookies } = this.props
        let isAuth = cookies.get('isAuth')

        if(isAuth === Password)
            this.setState({isAuth: true})
    }

    setAnswer(index, option) {
        let answers = this.state.answers

        answers[index] = option

        this.setState({answers})
    }

    showResult() {
        let results = {
            A: 0,
            B: 0,
            C: 0,
            D: 0
        }

        this.state.answers.map((answer, index) => {
            results[answer] = results[answer]+Questions[index].points
        })

        let endResult = Math.max(...Object.values(results))

        Object.keys(results).map(key => {
            if(results[key] === endResult)
                this.setState({isShowResult: key})
        })
    }

    join() {
        const { cookies } = this.props

        let expires = new Date()
        expires.setFullYear(expires.getFullYear()+1)

        cookies.set('isAuth', this.state.password, { path: '/', expires })

        if(this.state.password === Password) {
            this.setState({isAuth: true})
        } else
            this.setState({isErrorAuth: true})
    }

    clear() {
        this.setState({
            currentQuestionIndex: 0,
            answers: [],
            isShowResult: false,
        })
    }

    render() {
        return (<ChakraProvider>
            {this.state.isAuth && <Box width='100%' height='100vh' backgroundColor='#fff' paddingY='60px'>
                <Image src='https://api.liveframe.eu/media/WhJ6ioM1JHHHkwxHCxHmnO6k.png' ml='72px' width='250px' height='73px' />

                <Text fontWeight='black' color="#e63323" fontSize='36px' p='20px 80px'>#ActionForClimate</Text>
                {!this.state.isShowResult && <Box mt='10px'>
                    {Questions.map((question, index) => this.state.currentQuestionIndex === index && <Box key={question.title}>
                        <Text backgroundColor='#e63323' p='20px 80px' color='#fff' fontWeight='bold' fontSize='24px'>{question.title}</Text>

                        <Box p='20px 80px'>
                            <RadioGroup onChange={(option) => this.setAnswer(index, option)} value={this.state.answers[index]}>
                                <Stack divider={<StackDivider borderColor='gray.200' />}>
                                    <Radio colorScheme='red' value='A'>{question.A}</Radio>
                                    <Radio colorScheme='red' value='B'>{question.B}</Radio>
                                    <Radio colorScheme='red' value='C'>{question.C}</Radio>
                                    <Radio colorScheme='red' value='D'>{question.D}</Radio>
                                </Stack>
                            </RadioGroup>
                            
                            {index !== 0 && <Button onClick={() => this.setState({currentQuestionIndex: index-1})} float='left' colorScheme='gray' borderRadius='0' mt='20px'>Înapoi</Button>}

                            <Box float='right' display='flex' mt='20px' alignItems='center'>
                                <Text>{index+1} / {Questions.length}</Text>
                                {index+1 < Questions.length && <Button 
                                    onClick={() => this.setState({currentQuestionIndex: index+1})}
                                    isDisabled={!this.state.answers[index]} 
                                    colorScheme='red' 
                                    borderRadius='0' 
                                    ml='15px'
                                >Mai departe</Button>}

                                {index+1 === Questions.length && <Button 
                                    onClick={() => this.showResult()}
                                    isDisabled={!this.state.answers[index]} 
                                    colorScheme='red' 
                                    borderRadius='0' 
                                    ml='15px'
                                >Rezultat</Button>}
                            </Box>
                        </Box>
                    </Box>)}
                </Box>}

                {!!this.state.isShowResult && <Box mt='10px'>
                    <Text backgroundColor='#e63323' p='20px 80px' color='#fff' fontWeight='bold' fontSize='24px'>Rezultat</Text>
                    <Text p='20px 80px' whiteSpace='break-spaces'>{Responses[this.state.isShowResult]}</Text>

                    <Button 
                        onClick={() => this.clear()}
                        colorScheme='red' 
                        borderRadius='0' 
                        mt='15px'
                        m='0px 80px'
                    >Înapoi</Button>
                </Box>}
            </Box>}
            {!this.state.isAuth && <Box display='flex' width='100%' height='100vh' alignItems='center' justifyContent='center' flexDirection='column'>
                <Image src='https://api.liveframe.eu/media/WhJ6ioM1JHHHkwxHCxHmnO6k.png' mb='72px' width='250px' height='73px' />
                <FormControl isInvalid={this.state.isErrorAuth} width='300px'>
                    <FormLabel htmlFor='email'>Parola</FormLabel>
                    <Input onEnter borderRadius='0' id='password' type='password' onChange={(e) => this.setState({password: e.target.value, isErrorAuth: false})} />
                    <FormErrorMessage>Parolă Invalidă.</FormErrorMessage>
                </FormControl>
                <Button 
                    onClick={() => this.join()}
                    colorScheme='red' 
                    borderRadius='0' 
                    mt='15px'
                >Enter</Button>
            </Box>}
        </ChakraProvider>)
    }
}

export default withCookies(Eon)
